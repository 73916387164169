import 'bootstrap/dist/css/bootstrap.min.css';
import "./MainPlaylist.css";
import { Container} from "react-bootstrap"
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PlaylistItem from './PlaylistItem-read_only';
import { useQuery } from '@tanstack/react-query'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const PLAYLIST_ID = "7sy1LynjWbAIzURm5ekqLh"; //1DWeZYfgG7OZlyUFUiDai4 -> SPOTIFY API PLAYLIST // 0BTXBuuH7LFHflOOhlremi -> SCHULBALL PLAYLIST // 0K0t4wbtH3ESM0MW18OCrd -> SCHULBALL[TecAG] PLAYLIST

const playlistItemHelper = [];

function MainPlaylist({token, userID}){
  const [currentSort, setCurrentSort] = useState("Likes");
  const [visiblePageNums, setVisiblePageNums] = useState([]);
  const [entriesCount, setEntriesCount] = useState(20);
  const [pageNums, setPageNums] = useState([]); //maybe setPageNums => +i instead of pageNums.push(i)
  const [width, setWidth] = useState(window.innerWidth);
  
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const currentPage = parseInt(queryParameters.get('page') ?? '1');
  const songsPerPage = 30;

  const songsQuery = useQuery({
    queryKey: ["songs", currentPage, currentSort],
    queryFn: () => 
      fetch(process.env.REACT_APP_BACKEND_URL + `api/playlistItems/${songsPerPage}/${currentPage}/${currentSort}`)
      .then(function(response){ return response.json(); })
      .then(function(data) {
      return(data.songQuery)})
  });

  // isRun to check if useEffect has already run because of React StrickMode
  const isRun = useRef(false);

  useEffect(() => { 
    
    if(isRun.current) return;

    isRun.current = true;

    fetch(process.env.REACT_APP_BACKEND_URL + 'api/playlistItems/entriesTotal')
    .then(result => {
      return result.json();
    })
    .then(data => {
      setEntriesCount(data.count);
      for(let i = 2; i <= Math.ceil(data.count/songsPerPage); i++){
        pageNums.push(i);
      }
    });

  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    setCurrentSort(queryParameters.get('sort') ? queryParameters.get('sort') : "Likes");
    //get songs from backend

    if(currentPage > 2){
      if(currentPage === Math.ceil(entriesCount / songsPerPage)){
        setVisiblePageNums(pageNums.slice((currentPage-5),(currentPage)));
      } else {
        setVisiblePageNums(pageNums.slice((currentPage-3),(currentPage)));
      }
    } else {
      setVisiblePageNums([2, 3, 4]);
    }
  }, [currentPage, currentSort, pageNums, entriesCount]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  return(
  <div className="App">
     <div className='read-only-banner alert alert-primary' role='alert'>You're viewing the <b>Read-Only Version</b> of nicodenetworks! Please <a href='/schulball24'>Log In</a> to Vote!<br></br></div>
      <div className='userAvatarDropdown'>
       
      </div>
      <Container>
        <div className="heading1 text-center font-weight-bold">Jukebox Festival</div>
        <div className="heading2small text-center">Intermission - Songs</div>
      </Container>
      <Container className='inputBar inputHeader'>
        <button className='searchBtn' onClick={() => {
               
            }}>
              <img className='searchBtnIcon'src='./search.svg' alt='searchIcon'/>
            </button>
          <input 
          className="inputThing inputForm" 
          placeholder='Search by Song, Artist, url' 
          onKeyPress={event => {
                if (event.key === 'Enter' && event.target.value.length > 0) {
                 
                }
              }}
             >
          </input>
          <button className={'exitBtnSBhidden'} onClick={() => {
             
            }}>
              <img className='exitBtnIcon'src='./exit.svg' alt='searchIcon'/>
            </button>
      </Container>
      <div className='PlaylistItems'>
        {songsQuery.isFetching && (
              Array(songsPerPage).fill(0).map((item, i)=> 
              <SkeletonTheme key={i} baseColor="#ABABAB" highlightColor="#A0A0A0">
                <div className='PlaylistItem' style={{zIndex: "1" }}>
                <div className='PlaylistItemInfo'>
                  <div className='PLImageHelper'>
                   <Skeleton box width='90px' height='90px' borderRadius='15px' />
                  </div>
                  <div className='PLSongInfo'>
                    <div className='PLSTitle'><Skeleton  width={(width <= 768) ? '50vw' : '20rem'} borderRadius='7px'/></div>
                    <div className='PLArtist'><Skeleton  width={(width <= 768) ? '40vw' : '15rem'} borderRadius='7px'/></div>
                  </div>
                </div>
                </div>
              </SkeletonTheme>
            ))}
            {
              songsQuery.error ? (
                <div className='PLError text-center'>
                  404! <br></br> We couldn't get any songs from the server, <br></br> please try reloading this page, if this error persists, contact info@nicodenetworks.com.
                </div>
              ) : (
                songsQuery.data?.map((songs,y) => { //PL in this function is PLAYLIST
                  var songName = songs.songName;
                  playlistItemHelper.push(songs.song_uid);
                    return(
                        <PlaylistItem
                          uid={songs.song_uid} songName={songName} coverImage={songs.coverImage}
                          songArtists={songs.songArtists}
                          likes={songs.likes_total} key={songs.song_uid} 
                          className='PLItemHelper'></PlaylistItem>
                      )
                  })
              )
            } 
      </div>
      <div className='pagination'>
          <div className='PA-Heading'>showing <span className='PA-Heading-highlight'>{currentPage*songsPerPage>=entriesCount ? entriesCount : currentPage*songsPerPage}</span> of <span className='PA-Heading-highlight'>{entriesCount}</span> entries</div>
          <div className='PA-Nav'>
          <img className={`PA-arrow ${currentPage!==1 ? "activeArrow" : ""}`} src='./arrowLFT.svg' alt='' onClick={currentPage!==1 ? () => 
            {navigate(`?pl=1DWeZYfgG7OZlyUFUiDai4&page=${currentPage-1}&sort=${currentSort}&v=1`);  window.scrollTo(0, 0);} : ()=> {}}/>
          <div className='PA-content'>
            <span className='PA-firstNums'> <span className={currentPage === 1 && "activePage"} onClick={() => {navigate(`?pl=1DWeZYfgG7OZlyUFUiDai4&page=1&sort=${currentSort}&v=1`);  window.scrollTo(0, 0);}}>1 </span> {
              visiblePageNums.map(num => (
                <span className={`PA-firstNum ${currentPage===num ? "activePage" : ""}`} onClick={() => {navigate(`?pl=1DWeZYfgG7OZlyUFUiDai4&page=${num}&sort=${currentSort}&v=1`);  window.scrollTo(0, 0);}}>
                  {num}
                </span>
              ))
            }</span>
            {
              ((Math.ceil(entriesCount/songsPerPage) >= 5) && currentPage < Math.ceil(entriesCount/songsPerPage)-1) && (
                <>
                  <span className='PA-numDivider'>...</span>
                  <span className='PA-lastNum' onClick={() => {navigate(`?pl=1DWeZYfgG7OZlyUFUiDai4&page=${Math.ceil(entriesCount/songsPerPage)}&sort=${currentSort}&v=1`);  window.scrollTo(0, 0);}}>{Math.ceil(entriesCount/songsPerPage)}</span>
                </>
              )
            }
          </div>
          <img className={`PA-arrow ${currentPage<entriesCount/songsPerPage ? "activeArrow" : ""}`} src='./arrowRGT.svg' alt='' onClick={currentPage<entriesCount/songsPerPage ? () => 
            {navigate(`?pl=1DWeZYfgG7OZlyUFUiDai4&page=${currentPage+1}&sort=${currentSort}&v=1`);  window.scrollTo(0, 0);} : ()=> {}}/>
          </div>
      </div>
      <div className='StickyFooter'>

        <div className='StickyFooterBtnWrapper'>
          <a className='OpenInSpotify' href={'https://open.spotify.com/playlist/' + PLAYLIST_ID + '?si=5808afc00833410b'}>Spotify Playlist</a>
        </div>
        <div className='StickyFooterVersionTxt'>
          v0.1.26-alpha
        </div>

        <div className='StickyFooterBackground'>

        </div>
        
        {/*<div className='alert alert-info' role='alert'>Whoah, this Song already exists in this Playlist!</div>*/}
      </div>
          <div className='webInfoFooter'>
          <div className="backgroundSVGCurve">
              <div className='footerContent'>
                <div className='footerContentMain'>
                  <div className='footerCompanyInfos'>
                    <span className='footerCompanyName'>Copyright © 2024 Nicolas Pfeifer. <br></br> All rights reserved.</span>
                    <div className='footerProblemReport'>
                      <a href='mailto:info@nicodenetworks.com?subject=[Schulball24] Problem Report' className='footerProblemReportLink'>Report a Problem</a>
                    </div>
                  </div>
                  <div className='footerLegalInfo'>
                    <span>
                    <a href='/legal/terms-and-conditions' className='footerLegalTerms footerLegalInfoItem'>Terms and Conditions</a></span>
                    <span><a href='/legal/privacy ' className='footerLegalPrivacy footerLegalInfoItem'>Privacy Policy</a></span>
                    <span><a href='/legal/cookies' className='footerLegalCookies footerLegalInfoItem'>Cookies Notice</a></span>
                  </div>
                  <div className='footerCompanySocials'>
                    <div className='footerCompanyMailWrapper'>
                      <span className='footerCompanyMailHeading'>Mail:</span>
                      <div href='info@nicodenetworks.com' className='footerCompanyMail'>info@nicodenetworks.com</div>
                    </div>
                    <img className='footerCompanySocialsDivider' src='./divider2.svg' alt='divider'></img>
                    <div className='footerSocialInfo'>
                      <a href='https://www.github.com/nicode3141' className='footerSocialInfoItem'><img className='footerSocialIcon' src='./github.svg' alt='github icon'/></a>
                      <a href='https://github.com/nicode3141/spotifyAPI-keycloak-theme' className='footerSocialInfoItem'><img className='footerSocialIcon' src='./instagram.svg' alt='instagram icon '/></a>
                    </div>
                  </div>
                </div>
                
              </div>
          </div>
          </div>
    </div>

    );

}

export default MainPlaylist;
