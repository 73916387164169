import 'bootstrap/dist/css/bootstrap.min.css';
import "./MainPlaylist.css";
import { Container, Row} from "react-bootstrap"
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import {motion, AnimatePresence} from 'framer-motion'
import PlaylistItem from './PlaylistItem';
import DropdownItem from './DropdownItem';
import { useQuery, useMutation} from '@tanstack/react-query'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import LazyImage from './LazyImage';
import MoreSongInfoDropdown from './MoreSongInfoDropdown';

const PLAYLIST_ID = process.env.REACT_APP_PLAYLIST_SOURCE;//"7sy1LynjWbAIzURm5ekqLh"; //1DWeZYfgG7OZlyUFUiDai4 -> SPOTIFY API PLAYLIST // 0BTXBuuH7LFHflOOhlremi -> SCHULBALL PLAYLIST // 0K0t4wbtH3ESM0MW18OCrd -> SCHULBALL[TecAG] PLAYLIST

const playlistItemHelper = [];

function MainPlaylist({token, userID}){
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [showSongInfoDropdown, setShowSongInfoDropdown] = useState(false);
  const [currentSongDropdown, setCurrentSongDropdown] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [searchError, setSearchError] = useState();
  const [addedSongs, setAddedSongs] = useState([]);
  const [addedSpotifySongs, setAddedSpotifySongs] = useState();
  const [addedSongsList, setAddedSongsList] = useState([]);
  const [searchDone, setSearchDone] = useState();
  const [songs, setSongs] = useState([]);
  const [illegalCharacter, setIllegalCharacter] = useState(false);
  const [playlistSongs, setPlaylistSongs] = useState([]);
  const [searchLimit, setSearchLimit] = useState(2);
  const [userInfo, setUserInfo] = useState();
  const [playingAudio, setPlayingAudio] = useState(false);
  const [currentSort, setCurrentSort] = useState("Likes");
  const [activePreview, setActivePreview] = useState(null);
  const [sortOptions, setSortOptions] = useState(["Likes", "Least Likes", "Date Added", "A-Z"]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [visiblePageNums, setVisiblePageNums] = useState([]);
  const [entriesCount, setEntriesCount] = useState(6);
  const [pageNums, setPageNums] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  
  const navigate = useNavigate();
  const audioRef = useRef(null);  
  const queryParameters = new URLSearchParams(window.location.search);
  const currentPage = parseInt(queryParameters.get('page') ?? '1');
  const songsPerPage = parseInt(process.env.REACT_APP_SONGS_PER_PAGE);

  const songsQuery = useQuery({
    queryKey: ["songs", currentPage, currentSort, addedSongsList],
    nextFetchPolicy: 'cache-first',
    queryFn: () => 
      fetch(process.env.REACT_APP_BACKEND_URL + `api/playlistItems/${songsPerPage}/${currentPage}/${currentSort}/${userID.userID}`)
      .then(function(response){ return response.json(); })
      .then(function(data) {
      return(data.songQuery)})
  });

  //-> array -> addedSongs for optAddSongs
 
  const addSongQuery = useMutation({
    mutationFn: (songParams) => fetch(process.env.REACT_APP_BACKEND_URL + 'api/playlistItems/', songParams)
    .then(res => {
      return res.json();
    }).then(data => {
      setAddedSongsList(addedSongsList.concat(data.song_uid));
      setEntriesCount(entriesCount+1);
      return {data}
    }),
    onMutate: (newSong) => {
      var uid = JSON.parse(newSong.body).song_uid;
      setAddedSongsList(addedSongsList.concat(uid));
    },
    onError: (newSong) => {
      // won't ever happen :)
      /*var uid = JSON.parse(newSong.body).uid;
      let addedWithoutUid = addedSongsList.filter(item => item !== uid);
      console.log(addedWithoutUid);
      setAddedSongsList(addedWithoutUid);*/
    },
    onSettled: () => {
      songsQuery.refetch();
      fetch(process.env.REACT_APP_BACKEND_URL + 'api/playlistItems/entriesTotal')
      .then(result => {
        if(!result.ok){
          throw new Error("Something went wrong, try reloading your page...")
        }
        return result.json();
      })
      .then(data => {
        setEntriesCount(data.count);
        setPageNums(Array.from({ length: data.count/songsPerPage}, (_, index) => index + 2));
      })
      .catch(error => {
        //error display...
      })
    }
  })

  // isRun to check if useEffect has already run because of React StrickMode
  const isRun = useRef(false);

  useEffect(() => { 
    
    if(isRun.current) return;

    isRun.current = true;

    fetch(process.env.REACT_APP_BACKEND_URL + 'api/playlistItems/entriesTotal')
    .then(result => {
      if(!result.ok){
        throw new Error("Something went wrong, try reloading your page...")
      }
      return result.json();
    })
    .then(data => {
      setEntriesCount(data.count);
      setPageNums(Array.from({ length: (data.count)/songsPerPage}, (_, index) => index + 2));
      
    })
    .catch(error => {
      //error handling
    })

    if(currentPage > 4){
      setVisiblePageNums(pageNums.slice((currentPage-4),(currentPage)));
    } else if(entriesCount/songsPerPage < 4){
      setVisiblePageNums(pageNums);
    }else {
      setVisiblePageNums([2, 3, 4]);
    }

    
    //called if userID does not exist in backend DB
    function addUser(id){
      var userParams = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token.token}`
        },
        body: JSON.stringify({userID: id}) 
      }

      fetch(process.env.REACT_APP_BACKEND_URL + 'api/users/', userParams)
      .then(res => {
        if(!res.ok){
          throw new Error("Something went wrong, try reloading your page...")
        }
        return res.json();
      })
      .then(data => {
        setUserInfo(data);
      })
      .catch(error => {
        //error handling
      })
    }


    //called in useEffect // when rerun
    function getUser(id){
      //get user
      var userParams = {
        headers: {
          'authorization': `Bearer ${token.token}`, //Token gets packaged into a new Object in MainPlaylistHandler // may be fixed in future...🫥
        },
      };
      fetch(process.env.REACT_APP_BACKEND_URL + 'api/users/' + id, userParams)
      .then(res => {
        if(!res.ok && (res.statusText === 'Not Found' || res.status === 404)){
          //create new user request
          addUser(id);
        } else{
          if(!res.ok){
            throw new Error("Something went wrong, try reloading your page...");
          }
          return res.json();
        }
      })
      .then(data => {
      setUserInfo(data);
    })
    }

    getUser(userID.userID);

  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  //close search window, when page changes
  useEffect(() => {
    setShowSearchResults(false);
  }, [currentPage]);

  //function to keep track of current audio elements played 
  //stops audio when another is being played
  useEffect(() => {
    const stopAudio = () => {
      if(playingAudio){
          setPlayingAudio(false);
      }
    }
    if(playingAudio){
    audioRef.current.addEventListener('ended', stopAudio());
    }

  }, []);

  useEffect(() => {
    setCurrentSort(queryParameters.get('sort') ? queryParameters.get('sort') : "Likes");

    if(currentPage > 2){
      if(currentPage === Math.ceil(entriesCount / songsPerPage)){
        setVisiblePageNums(pageNums.slice((currentPage-5),(currentPage)));
      } else {
        setVisiblePageNums(pageNums.slice((currentPage-3),(currentPage)));
      }
    } else {
      setVisiblePageNums([2, 3, 4]); //maybebug?
    }

  }, [currentPage, currentSort, pageNums, addedSongsList]);

  useEffect(() => {
    if(playingAudio){
      togglePlay(activePreview);
    }
  }, [currentPage]);


  //dropdown menu close when clicked outside of menu  
  useEffect(() => {
    const closeDropdown = e =>{
      if(e.target.className !== 'dropdownAvatar' && e.target.className !== 'avatarImg'){
        setShowProfileDropdown(false);
      }

    }

    document.body.addEventListener('click', closeDropdown);
    return () => document.body.removeEventListener('click', closeDropdown);
  }, [])

    //dropdown menu close when clicked outside of menu  
    useEffect(() => {
      const closeSongDropdown = e =>{
        
        if(e.target.className !== 'SearchAddInfo' && e.target.className !== 'PLSongLikeImg' && e.target.className !== 'DAlikeFieldBtn' && e.target.className !== 'DASongLikeCount' && e.target.className !== 'DAlikeTxt'){
          setShowSongInfoDropdown(false);
        }
  
      }
  
      document.body.addEventListener('click', closeSongDropdown);
      return () => document.body.removeEventListener('click', closeSongDropdown);
    }, [])

  async function search(n, newSearch) {
    //when search is not null or undefined OR has no whitespaces ->
    //currently very slow!
    if(searchInput.length !== null && searchInput.trim().length > 0) {
      setShowSearchResults(true);
      var illegalSearch = ["{", "}", "&&", "||", "(^...$)", "?"]
      if(!["{", "}", "&&","?"].includes(searchInput)){
      setSearchError();
      setIllegalCharacter(false);
      setIsLoadingSearch(true);

      const searchParams = {
        method: 'GET',
        headers: {
          'Content-Type' : 'application/json',
          'Authorization': `Bearer ${token.token}`
        }
      }

      if(searchInput.includes('open.spotify.com')){
      //get id from url
        var songID = searchInput.substring(searchInput.lastIndexOf('/') + 1).split('?')[0];
        fetch(process.env.REACT_APP_BACKEND_URL + 'api/search/track/' + songID, searchParams)
        .then(response => {
          if(!response.ok){
            try{
              
            } catch(err) {

            }
            throw new Error('Something went wrong, try reloading your page...')
          }
          return response.json()
        })
          .then(data => {
            if(data?.added === true){
              setIsLoadingSearch(false);
              setAddedSpotifySongs(data);
              setSongs([]);
              setAddedSongs([]);
            } else{
              setIsLoadingSearch(false);
              setSongs(songs.concat(data));
              setAddedSongs([]);
              setAddedSpotifySongs();
            }
          })
        .catch(error => {
          setIsLoadingSearch(false);
          setSearchError(error);
        })
      } else {
      //loading data...
      //when addedSongs is empty
      //search inside DB, then fetch from spotify
      if(searchDone !== searchInput){ //if last result was empty: skip part of searching inside components
        fetch(process.env.REACT_APP_BACKEND_URL + 'api/search/added/' + searchInput + "/2/" + parseInt(n-2) + "/" + userID.userID) //string (search), limit, skip 
        .then(response => {
          if(!response.ok){
            throw new Error("Something went wrong, try reloading your page...")
          }
          return response.json();
        }).then(data => {
          
          if(data.addedQuery.length > 0){
            setIsLoadingSearch(false);
            setAddedSongs(newSearch? data.addedQuery : addedSongs.concat(data.addedQuery));
            if(newSearch){
              setSongs(newSearch && []);
              setAddedSpotifySongs();
            }
            //setAddedSongs(n<3? data.items : songs.concat(data.items));
          } else {
            setSearchLimit(0); //reset skip/offset counter after DB search
            setSearchDone(searchInput);
            searchSpotifySong(0, searchParams, newSearch)
          }
        }).catch(error => {
            setSearchError(error)
        }).finally(() => {
          setIsLoadingSearch(false);
        })
      } else {
        searchSpotifySong(searchLimit+2, searchParams, newSearch); //normaly search Spotify Song
      }
    }}
    } else{
      //searchString contains illegal character(s)
      setIllegalCharacter(true);
    }

  };


  async function searchSpotifySong(n, searchParams, newSearch){
    //call backend endpoint /api/search with parameters: limit and offset // include_external=audio ? 
    fetch(process.env.REACT_APP_BACKEND_URL + 'api/search/' + searchInput + "/2/" + n, searchParams)
    .then(response => {    
      if(!response.ok){
        throw new Error("Something went wrong, try reloading your page...");
      }     
      return response.json();
    }).then(data => {
      setIsLoadingSearch(false);
      setSongs(newSearch? data.tracks.items : songs.concat(data.tracks.items));
      if(newSearch){
        setAddedSongs(newSearch && []);
        setAddedSpotifySongs();
      }
    })
    .catch(error => {
      setSearchError(error);
    })
    .finally(() => {
      setIsLoadingSearch(false);
    })
}
  

  async function addItemToPlaylist(reqUserID, userToken, songName, songId, coverImage, songArtists, previewUrl) {
    //NOTE: songsArtists is a string with all artists, joined by a comma
    //add song to DB req call

    var songParams = {
      method: 'POST',
      headers: {
        'Content-Type' : 'application/json',
        'Authorization': `Bearer ${userToken}`
      },
      body: JSON.stringify({songName: songName, uid: songId, coverImage: coverImage, songArtists: songArtists, previewUrl: previewUrl, reqUserID: reqUserID})
    }

    addSongQuery.mutate(songParams);
  }

  function addSong(reqUserID, userToken, songName, songId, coverImage, songArtists, previewUrl) {

      //check if the song already exists
      var songsPl = [];

      playlistSongs.map((items,i) => {
        songsPl.push(items.track.id);
      })
      
      if(songsPl.includes(songId)) {
        } else {
          addItemToPlaylist(reqUserID, userToken, songName, songId, coverImage, songArtists, previewUrl);
          //addSongQuery.mutate(reqUserID, userToken, songName, songId, coverImage, songArtists, previewUrl);
        }
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const togglePlay = (url) => {
    if(activePreview === url){
      playingAudio ? audioRef.current.pause() : audioRef.current.play();
      setPlayingAudio(!playingAudio);
    } else{
      if (audioRef.current){
        audioRef.current.pause();
      }

      setActivePreview(url);
      setPlayingAudio(true);
      audioRef.current = new Audio(url);
      audioRef.current.volume = 0.5;
      audioRef.current.play();
        
    }
  }

  function scrollToItem(itemID){
    //IN DEV
  }

  return(
  <div className="App">

      <div className='headerContainer'>
      <div className='headerProjectName'>{process.env.REACT_APP_ALT_TITLE}</div>
        <div className='userHeading' onClick={() => setShowProfileDropdown(!showProfileDropdown)}>
          <img className='dropdownAvatar' src='dropdownAvatar.svg' alt='avatarDivider'/>
          <img className='avatarImg' imgclassname='avatarImg' src={'./avatar.jpg'} alt='avatar' 
          height='50px' width='50px' radius='15' onError={({currentTarget}) => currentTarget.src = './loadingAvatar.jpg'}/>
        </div>
      </div>
      <div className='userAvatarDropdown'>
        <DropdownItem isVisible={showProfileDropdown} username={userInfo? userInfo.username : "username"}></DropdownItem>
      </div>
      
      <Container>
        <div className="heading1">{process.env.REACT_APP_TITLE}</div>
        <div className="heading2small text-center">Songs</div>
      </Container>

      <AnimatePresence>
      {showDeleteAlert && (
        <>
        <div className='DELBackdrop'/>
          <div className="DelAlertWrapper">
          <motion.div className='DelAlert' initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity:0}}>

              <div className='DELHeading'>Notice</div>
              <div className='DELContent '>
                <span className='DELInfo'>To protect this vote against fraud, you can’t delete posts with more than 1 like. </span>
                <a className='DELLink' href='/guidelines?item-deletion'>more information</a>
              </div>
              <div className='DELUnderstandWrapper'>
              <button className='DELUnderstandBtn' onClick={() => setShowDeleteAlert(false)}>I understand</button></div>
          </motion.div> 
          </div>
          </>
        )}
        </AnimatePresence>
      <Container className='inputBar inputHeader'>
        <button className='searchBtn' onClick={() => {
                setSearchLimit(2);
                search(2, true);
            }}>
              <img className='searchBtnIcon'src='./search.svg' alt='searchIcon'/>
            </button>
          <input 
          className="inputThing inputForm" 
          placeholder='Search by Song, Artist' 
          onKeyPress={event => {
                if (event.key === 'Enter' && event.target.value.length > 0) {
                  setSearchLimit(2);
                  search(2, true);
                }
              }}
              onChange={event => setSearchInput(event.target.value)}>
          </input>
          <button className={'exitBtnSB' + (showSearchResults ? 'visible' : 'hidden')} onClick={() => {
                setShowSearchResults(false); 
            }}>
              <img className='exitBtnIcon'src='./exit.svg' alt='searchIcon'/>
            </button>
      </Container>
      <Container className={'SearchResults ' + (showSearchResults? "" : "hiddenBX")}>
        <div className='SearchResultItems' key="modal" initial={{ height: 0, opacity: 0 }} animate={{ 
                      height: "auto" , opacity: 1, 
                      transition:{opacity: {delay: 0.025}, duration: 1}
                    }} exit={{ height: 0, opacity: 0, duration: 1 }} >
          { illegalCharacter && (
            <div className='SBError'>
              <div className="SBErrorMsg">Your search can't contain any of the following characters: </div>
              <div className='SBErrorSecondary'>?, /, §, $, \</div>
            </div>)
          }
          {searchError && 
            (
            <div className='SBError'>
              <div className="SBErrorMsg">Something went wrong, try reloading your page...</div>
              <div className='SBErrorSecondary'>If this error persists, contact info@nicodenetworks.com</div>
            </div>
            ) 
          }
        <Row className='row row-cols-md-2 xs-row-cols-xs-1'>
          {
          (addedSpotifySongs!==undefined&&addedSpotifySongs!==null&&!searchError) && (
            <motion.div initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}>
                <div key={`${addedSpotifySongs.song_uid}added`} className='SBResultWrapper'>
                  <div className='SBResult '>
                    <div className='SBResultInfo'>
                      <div className='SBImageHelper'>
                      <LazyImage imgClassName='SBResultImage' src={addedSpotifySongs?.album.images[0].url} 
                        width={(width <= 768) ? '60px' : '70px'} 
                        height={(width <= 768) ? '60px' : '70px'} colorPrimary='#A0A0A0' colorSecondary='#ABABAB' 
                        radius={(width <= 768) ? '10px' : '20px'}/>
                        <div className='PLPreviewPlayer'>
                        { (addedSpotifySongs.preview_url) ? 
                        ((addedSpotifySongs.preview_url === activePreview) && playingAudio) ? (
                        <div className='PLPreview'>
                      
                        
                        <img className='PLImagePreviewPlayer' src='./stopBtn.svg' width={25} height={25} onClick={() => togglePlay(addedSpotifySongs.preview_url)} alt='previewButton'></img></div>
                        ) :
                        (
                          <div className='PLPreview'>
                            <img className='PLImagePreviewPlayer' src='./playBtn.svg' width={25} height={25} onClick={() => togglePlay(addedSpotifySongs.preview_url)} alt='previewButton'></img>
                      </div>
                      )
                    
                : (<></>) 
                }
              </div>
                      </div>
                      <div className='SBResultText'>
                        <div className='SBResultTitle text-nowrap text-truncate' ellipsizemode='tail' numberoflines={2}>{addedSpotifySongs.name}</div>
                        <div className='SBResultArtist'>{addedSpotifySongs.artists.map((artist) => artist.name).join(', ')}</div>
                        {/*<button className=''></button>*/}
                      </div> 
                    </div>

                    <button className={'addSongBtn ' + "select"}
                      onClick={() =>  scrollToItem(addedSpotifySongs.id)}>
                        {/*Object.values(fullSongUidList).includes(items.id) ? (<>Added</>) : (<>Add</>)*/ "Added"}</button>
                    
                    
                  </div>
              </div>
            </motion.div>
            ) 
          
          }
          { (addedSongs&&!searchError) && addedSongs?.map((items,i) => {
             return (
              
              <motion.div initial={{ opacity: 0 }} key={items.song_uid}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}>
                
                <div  className='SBResultWrapper'>
                  <div className='SBResult '>
                    <div className='SBResultInfo'>
                      <div className='SBImageHelper'>
                      <LazyImage imgClassName='SBResultImage' src={items.coverImage} width={(width <= 768) ? '60px' : '70px'} 
                      height={(width <= 768) ? '60px' : '70px'} colorPrimary='#A0A0A0' colorSecondary='#ABABAB' radius={(width <= 768) ? '10px' : '20px'}/>
                        <div className='PLPreviewPlayer'>
                        { (items.previewUrl) ? 
                        ((items.previewUrl === activePreview) && playingAudio) ? (
                        <div className='PLPreview'>
                      
                        
                        <img className='PLImagePreviewPlayer' src='./stopBtn.svg' width={25} height={25} onClick={() => togglePlay(items.previewUrl)} alt='previewButton'></img></div>
                        ) :
                        (
                          <div className='PLPreview'>
                            <img className='PLImagePreviewPlayer' src='./playBtn.svg' width={25} height={25} onClick={() => togglePlay(items.previewUrl)} alt='previewButton'></img>
                      </div>
                      )
                    
                : (<></>) 
                        
              }
              </div>
                      </div>
                      <div className='SBResultText'>
                        <div className='SBResultTitle text-nowrap text-truncate' ellipsizemode='tail' numberoflines={2}>{items.songName}</div>
                        <div className='SBResultArtist'><span className='SBResultArtistAdded'>Added</span> - {items.songArtists}</div>
                        {/*<button className=''></button>*/}
                      </div> 
                    </div>
                    
                      <button className={"SearchAddInfo"}
                        onClick={() => { setShowSongInfoDropdown(!showSongInfoDropdown); setCurrentSongDropdown(items.song_uid) }}>
                        <img className={'SearchAddInfo'} src='verticalDropdown.svg'  width={40}  alt='addInfo'/>
                      </button>
                      <MoreSongInfoDropdown isVisible={showSongInfoDropdown && currentSongDropdown===items.song_uid} userID={userID.userID} token={token.token} uid={items.song_uid} songName={items.songName} likes_total={items.likes_total} liked={items.liked}/>
                  </div>
              </div>
            </motion.div>
            ) 
          })}{(!searchError) &&
            //maybe add "Added" to song tile instead of deleting existing songs
          songs?.filter(({id}) => !new Set(addedSongs.map(({song_uid}) => song_uid)).has(id))
          .map((items,i) => {
            var artists = items.artists.map((artist) => artist.name).join(', ');
            return (
              <motion.div initial={{ opacity: 0 }} key={items.id}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}>
                <div  className='SBResultWrapper'>
                  <div className='SBResult '>
                    <div className='SBResultInfo'>
                      <div className='SBImageHelper'>
                        <LazyImage imgClassName='SBResultImage' src={items.album.images[0].url} width={(width <= 768) ? '60px' : '70px'} height={(width <= 768) ? '60px' : '70px'} colorPrimary='#A0A0A0' colorSecondary='#ABABAB' radius={(width <= 768) ? '10px' : '20px'}/>
                        <div className='PLPreviewPlayer'>
                        { (items.preview_url) ? 
                        ((items.preview_url === activePreview) && playingAudio) ? (
                        <div className='PLPreview'>
                      
                        
                        <img className='PLImagePreviewPlayer' src='./stopBtn.svg' width={25} height={25} onClick={() => togglePlay(items.preview_url)} alt='previewButton'></img></div>
                        ) :
                        (
                          <div className='PLPreview'>
                            <img className='PLImagePreviewPlayer' src='./playBtn.svg' width={25} height={25} onClick={() => togglePlay(items.preview_url)} alt='previewButton'></img>
                      </div>
                      )
                    
                : (<></>)
              }
              </div>
                      </div>
                      <div className='SBResultText'>
                        <div className='SBResultTitle text-nowrap text-truncate' ellipsizemode='tail' numberoflines={2}>{items.name}</div>
                        <div className='SBResultArtist'>{artists}</div>
                        {/*<button className=''></button>*/}
                      </div> 
                    </div>
                    <button className={'addSongBtn ' + (addedSongsList.includes(items.id) ? "select": "")}
                      onClick={() => addedSongsList.includes(items.id) ? scrollToItem(items.id) : addSong(userID.userID, token.token, items.name, items.id, items.album.images[0].url, artists, items.preview_url, [])  /* reqUserID, userToken, songName, songId, coverImage, songArtists*/}>
                        {addedSongsList.includes(items.id)? (<>Added</>) : (<>Add</>)}</button>
                  </div>
              </div>
            </motion.div>
            )
          })}
          {
            
            isLoadingSearch && (
              Array(2).fill(0).map((item, i) => 
              <SkeletonTheme key={i} baseColor="#ABABAB" highlightColor="#A0A0A0">
                <motion.div className='SBResultWrapper'  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}>
                  <div className='SBResult '>
                    <div className='SBResultInfo'>
                      <div className='SBImageHelper'>
                      <Skeleton box width={(width <= 768) ? '60px' : '70px'} height={(width <= 768) ? '60px' : '70px'} borderRadius={(width <= 768) ? '10px' : '20px'} />
                      </div>
                      <div className='SBResultText'>
                      <div className='SBResultTitle'><Skeleton width="10rem"/></div>
                      <div className='SBResultArtist'><Skeleton width="6rem"/></div>
                      </div> 
                    </div>
                  </div>
              </motion.div>
            </SkeletonTheme>
              )
            )
          }
        </Row>
        </div>
        <div className="loadMoreFooter" onClick={
          () => {
            setSearchLimit(searchLimit + 2);
            search(searchLimit + 2, false);
          }
          }>
        <div className='loadMore'>
          <div className='lMImgHelper'>
            <img className='loadMoreImg' src='load.svg' width={20} height={20}></img>
          </div>
          <button className='loadMoreTxt'>Load more</button>
        </div>
        </div>
      </Container>
        <Container className='Sort-Options'>
          <div className='dropdown'>
            <Dropdown>
              <Dropdown.Toggle variant='light' className='Dd-So dropdown-basic'>
                <img className='sortIconBtn' src='sort.svg' width='25px' alt='sortIcon'></img>
                <div className='SortbyLabel'>{currentSort}</div>
              </Dropdown.Toggle><AnimatePresence>
              <Dropdown.Menu className='dropdown-Container' key={"dataField"}>
                  <motion.div
                   initial="collapsed"
                   animate="open"
                   exit="collapsed"
                   variants={{
                     open: { opacity: 1, height: "auto" },
                     collapsed: { opacity: 0, height: 0 }
                   }}
                   transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}>
                    {sortOptions.map((sortMethods, y) => {
                      if(sortMethods !==currentSort) {
                      return (
                        <Dropdown.Item key={sortMethods + " " + y} className='dropdown-txt' onClick={() => {
                          navigate(`?pl=1DWeZYfgG7OZlyUFUiDai4&page=1&sort=${sortMethods}`);  window.scrollTo(0, 0); 
                          setCurrentSort(sortMethods)}}>{sortMethods}</Dropdown.Item>
                      )} else{
                        return(<></>);
                      }
                      
                    })
                    }
                    </motion.div>
              </Dropdown.Menu>
              </AnimatePresence>
            </Dropdown>
          </div>
        </Container>
      <div className='PlaylistItems'>
        {songsQuery.isFetching && (
              Array(songsPerPage).fill(0).map((item, i)=> 
              <SkeletonTheme key={i} baseColor="#C2C2C2" highlightColor="#D0D0D0">
                <div className='PlaylistItem' style={{zIndex: "1" }}>
                <div className='PlaylistItemInfo'>
                  <div className='PLImageHelper'>
                   <Skeleton box width={(width <= 768) ? '65px' : '90px'} height={(width <= 768) ? '65px' : '90px'} borderRadius='15px' />
                  </div>
                  <div className='PLSongInfo'>
                    <div className='PLSTitle'><Skeleton  width={(width <= 768) ? '50vw' : '20rem'} borderRadius='7px'/></div>
                    <div className='PLArtist'><Skeleton  width={(width <= 768) ? '40vw' : '15rem'} borderRadius='7px'/></div>
                  </div>
                </div>
                </div>
              </SkeletonTheme>
            ))}
            {
              songsQuery.error ? (
                <div className='SBError'>
              <div className="SBErrorMsg">Something went wrong, try reloading your page...</div>
              <div className='SBErrorSecondary'>If this error persists, contact info@nicodenetworks.com</div>
            </div>
              ) : (
                songsQuery.data?.map((songs,y) => { //PL in this function is PLAYLIST
                  var songName = songs.songName;
                  playlistItemHelper.push(songs.song_uid);
                    return(
                        <PlaylistItem
                          uid={songs.song_uid} songName={songName} coverImage={songs.coverImage}
                          songArtists={songs.songArtists} previewUrl={songs.previewUrl}
                          likes={songs.likes_total} userLiked={songs.liked} addedBy={songs.added_by} userID={userID.userID}
                          token={token.token} key={songs.song_uid} playingAudio={playingAudio} activePreview={activePreview}
                           togglePlay={() => togglePlay(songs.previewUrl)} showDeleteAlert={setShowDeleteAlert}
                        className='PLItemHelper'></PlaylistItem>
                      )
                  })
              )
            } 
      </div>
      <div className='pagination'>
          <div className='PA-Heading'>showing <span className='PA-Heading-highlight'>{currentPage*songsPerPage>=entriesCount ? entriesCount : currentPage*songsPerPage}</span> of <span className='PA-Heading-highlight'>{entriesCount}</span> entries</div>
          <div className='PA-Nav'>
          <img className={`PA-arrow ${currentPage!==1 ? "activeArrow" : ""}`} src='./arrowLFT.svg' alt='' onClick={currentPage!==1 ? () => 
            {navigate(`?pl=1DWeZYfgG7OZlyUFUiDai4&page=${currentPage-1}&sort=${currentSort}`);  window.scrollTo(0, 0);} : ()=> {}}/>
          <div className='PA-content'>
            <span className='PA-firstNums'> 
            <span className={currentPage === 1 && "activePage"} onClick={() => {navigate(`?pl=1DWeZYfgG7OZlyUFUiDai4&page=1&sort=${currentSort}`);
              window.scrollTo(0, 0);}}>1 
              </span> 
              {
              visiblePageNums.map(num => (
                <span key={num} className={`PA-firstNums ${currentPage === num ? "activePage" : ""}`} 
                onClick={() => {navigate(`?pl=1DWeZYfgG7OZlyUFUiDai4&page=${num}&sort=${currentSort}`);  window.scrollTo(0, 0);}}>
                  {num}
              </span>
              ))
            }</span>
            {
              ((Math.ceil(entriesCount/songsPerPage) >= 5) && currentPage < Math.ceil(entriesCount/songsPerPage)-1) && (
                <>
                  { !(currentPage >= (entriesCount/songsPerPage)-2) && (
                      <span className='PA-numDivider'>...</span>
                    )
                  }
                  <span className='PA-lastNum' onClick={() => {navigate(`?pl=1DWeZYfgG7OZlyUFUiDai4&page=${Math.ceil(entriesCount/songsPerPage)}&sort=${currentSort}`);  window.scrollTo(0, 0);}}>
                    {Math.ceil(entriesCount/songsPerPage)}</span>
                </>
              )
            }
          </div>
          <img className={`PA-arrow ${currentPage<entriesCount/songsPerPage ? "activeArrow" : ""}`} src='./arrowRGT.svg' alt='' onClick={currentPage<entriesCount/songsPerPage ? () => 
            {navigate(`?pl=1DWeZYfgG7OZlyUFUiDai4&page=${currentPage+1}&sort=${currentSort}`);  window.scrollTo(0, 0);} : ()=> {}}/>
          </div>
      </div>
      <div className='StickyFooter'>

        <div className='StickyFooterBtnWrapper'>
          <a className='OpenInSpotify' href={'https://open.spotify.com/playlist/' + PLAYLIST_ID + '?si=5808afc00833410b'}>Spotify Playlist</a>
        </div>
        <div className='StickyFooterVersionTxt'>
          v0.1.26-alpha
        </div>

        <div className='StickyFooterBackground'>

        </div>
        
        {/*<div className='alert alert-info' role='alert'>Whoah, this Song already exists in this Playlist!</div>*/}
      </div>
          <div className='webInfoFooter'>
          <div className="backgroundSVGCurve">
              <div className='footerContent'>
                <div className='footerContentMain'>
                  <div className='footerCompanyInfos'>
                    <span className='footerCompanyName'>Copyright © 2024 Nicolas Pfeifer. <br></br> All rights reserved.</span>
                    <div className='footerProblemReport'>
                      <a href='mailto:info@nicodenetworks.com?subject=[Schulball24] Problem Report' className='footerProblemReportLink'>Report a Problem</a>
                    </div>
                  </div>
                  <div className='footerLegalInfo'>
                    <span>
                    <a href='/legal/terms-and-conditions' className='footerLegalTerms footerLegalInfoItem'>Terms and Conditions</a></span>
                    <span><a href='/legal/privacy ' className='footerLegalPrivacy footerLegalInfoItem'>Privacy Policy</a></span>
                    <span><a href='/legal/cookies' className='footerLegalCookies footerLegalInfoItem'>Cookies Notice</a></span>
                  </div>
                  <div className='footerCompanySocials'>
                    <div className='footerCompanyMailWrapper'>
                      <span className='footerCompanyMailHeading'>Mail:</span>
                      <div href='info@nicodenetworks.com' className='footerCompanyMail'>info@nicodenetworks.com</div>
                    </div>
                    <img className='footerCompanySocialsDivider' src='./divider2.svg' alt='divider'></img>
                    <div className='footerSocialInfo'>
                      <a href='https://www.github.com/nicode3141' className='footerSocialInfoItem'><img className='footerSocialIcon' src='./github.svg' alt='github icon'/></a>
                      <a href='https://github.com/nicode3141/spotifyAPI-keycloak-theme' className='footerSocialInfoItem'><img className='footerSocialIcon' src='./gov.png' alt='github icon' height="60px" width="60px"/></a>
                    </div>
                  </div>
                </div>
                
              </div>
          </div>
          </div>
    </div>

    );

}

export default MainPlaylist;
