import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import {useRef } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import {motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useQuery, useQueryClient, QueryClient, QueryClientProvider, useMutation } from '@tanstack/react-query'
import LazyImage from './LazyImage';

const PlaylistItem = ({uid, songName, coverImage, songArtists, previewUrl, likes, userLiked, addedBy, userID, token, activePreview, playingAudio, togglePlay, showDeleteAlert}) => {

  const [like, setLike] = useState(likes);
  const [prevLike, setPrevLike] = useState(likes);
  const [isLoadingLike, setIsLoadingLike] = useState(false);
  const [liked, setLiked] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [showPlaylistActions, setShowPlaylistActions] = useState(false);
  //const {setSong, pauseSong} = useAudio();


  let menuRef = useRef();
  let btnAnim = useAnimation();

  var likeParams = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({uid: uid, reqUserID: userID})
  }

  const queryClient = useQueryClient()  

  //needs optimistic updates!!!
  //currently only updates liked status optimistically
  const likeQuery = useMutation({
    nextFetchPolicy: 'cache-first',
    mutationKey: ['likeAdd'],
    mutationFn: () => 
      fetch(process.env.REACT_APP_BACKEND_URL + 'api/likes/like', likeParams)
      .then((res) => {
        return res.json()
      }).then((data) => {
        //console.log(data.likes.length);
        setLike(data.likes_total);
        setLiked(data.liked);
        setIsLoadingLike(false);
      }),
      onMutate: () => {
        setLiked(true);
        setLike(like + 1);
        setIsLoadingLike(true);
      },
      onError: () => {
        setLiked(false);
        setLike(like);
        setIsLoadingLike(false);
      },
      onSettled: () => {
        //setPrevLike(prevLike+1)
      }
      /*
    onMutate: async () => {
      await queryClient.cancelQueries({queryKey: ['likeAdd']})

      const previousLikes = queryClient.getQueriesData(['likeAdd'])

      queryClient.setQueryData(['likeAdd'], (previousLikes || 0) + 1);
      return { previousLikes }
    },
    onError: (err) => {
      queryClient.setQueryData(['likeAdd'] , () => err?.previousLikes)
    },
    onSettled: () => {
      queryClient.invalidateQueries(['likeAdd', {like: 'newLike'}]);
    }*/
  });

  const dislikeQuery = useMutation({
    mutationFn: () =>
    fetch(process.env.REACT_APP_BACKEND_URL + 'api/likes/unLike', likeParams)
      .then((res) => {
        return res.json()
      }).then((data) => {
        setLike(data.likes_total)
        setLiked(data.liked);
        setIsLoadingLike(false);
      }),
      onMutate: () => {
        setLiked(false);
        setLike(like - 1);
        setIsLoadingLike(true);
      },
      onError: () => {
        setLiked(true);
        setLike(like);
        setIsLoadingLike(false);
      },
      onSettled: () => {
        
      }


      /*
      onMutate: async () => {
        await queryClient.cancelQueries({queryKey: ['dislike']})
  
        const previousLikes = queryClient.getQueriesData(['dislike'])
  
        queryClient.setQueryData(['dislike'], (previousLikes || 0) - 1);
        return { previousLikes }
      },
      onError: (err) => {
        queryClient.setQueryData(['dislikes'] , () => err?.previousLikes)
      },
      onSettled: () => {
        queryClient.invalidateQueries(['dislike']);
      }*/
  });

  useEffect(() => {
    const closeDropdown = e => {
      if(!menuRef.current?.contains(e.target)){
        setShowPlaylistActions(false);
      }
      }
    document.body.addEventListener('click', closeDropdown);
    return () => document.body.removeEventListener('click', closeDropdown);
    
  }, []);

  useEffect(() =>{
    setLiked(userLiked);
  }, [userID, likes]);

  async function addLike(uid, userID, token){
    likeQuery.mutate();
  }

  async function removeLike(uid, userID, token){
    dislikeQuery.mutate();
  }

  
  async function removeItem(uid, userID, token){
    var removeParams = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({uid: uid})
    }

    fetch(process.env.REACT_APP_BACKEND_URL + 'api/playlistItems', removeParams)
    .then((res) => {
      return res.json()
    }).then((data) => {
      setDeleted(data.affectedRows>0);
    })
  }

  /*
  function truncate(str, n){
    return (str.length > n) ? str.slice(0, n-1) + '...' : str;
  };
  */
  

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

    if(!deleted) return(
     
        <div className='PlaylistItem' style={{zIndex: showPlaylistActions ? (width >= 768) ? "10" : "99" : "1" }}>
          <div className='PlaylistItemInfo'>
            <div className='PLImageHelper'>
              <div className='PLPreviewPlayer'>
                { (previewUrl) ?
                  ((previewUrl === activePreview) && playingAudio === true) ? (
                    <div className='PLPreview'>
                      
                        
                        <img className='PLImagePreviewPlayer' src='./stopBtn.svg' onClick={() => togglePlay()} alt='previewButton'></img></div>
                    ) :
                    (
                      <img className='PLImagePreviewPlayer' src='./playBtn.svg' onClick={() => togglePlay()} alt='previewButton'></img>
                    )
                    : (<></>)
              }
              </div>
                <LazyImage imgClassName='PLImage' src={coverImage} width='65px' height='65px' margin-top="-2px" colorPrimary='#A0A0A0' colorSecondary='#ABABAB' radius='11px'/>
            </div>
            <div className='PLSongInfo'>
              <div className='PLSTitle'>{songName}</div>
              <div className='PLArtist'>{songArtists}</div>
            </div>
          </div>
          <div className='PLSongLikeField'>
            <motion.div >
              <motion.img className='PLSongLikeImg' src={liked ? 'liked.svg' : 'like.svg'} alt='likeIcon' width='25px' initial={{ opacity: 0, scale: 0.5 }} 
              animate={{ opacity: 1, scale: 1 }} transition={{  type: 'spring', duration: 0.8, bounce: .4 }} whileTap={{ scale: 2 }}

              onClick={() => {liked ? removeLike(uid, userID, token) : addLike(uid, userID, token); btnAnim.start({scaleX: [.3, 1.2, 1]})}} disabled={isLoadingLike}></motion.img>
            </motion.div>
            {/*}
            <motion.svg width="33" height="29" viewBox="0 0 33 29" fill="none" xmlns="http://www.w3.org/2000/svg" initial={{scale: .1}} animate={{scale: 1}} onClick={() => btnAnim.start({scale: [.3, 1]})}>
              <path id="Intersect" d="M16.5 26.8604L3.55715 13.6954C0.814285 10.9055 0.814285 6.37172 3.55715 3.58179C6.28603 0.806071 10.6997 0.806071 13.4286 3.58179L15.4304 5.61793L16.5 6.70593L17.5696 5.61793L19.5714 3.58179C22.3003 0.806071 26.714 0.806071 29.4429 3.58179C32.1857 6.37172 32.1857 10.9055 29.4429 13.6954L16.5 26.8604Z" stroke="#7F7F7F" stroke-width="3"/>
              </motion.svg>*/}
                <motion.div className='PLSongLikeCount' initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }}
              transition={{  type: 'spring', duration: 0.8, bounce: .4 }}>{like}</motion.div>
          </div>
          <div ref={menuRef} className='PLSongAddInfo'>
            <button onClick={() => setShowPlaylistActions(!showPlaylistActions)}>
            <img className={'PLSongAddInfoIcon' + uid} src='verticalDropdown.svg' height={100} alt='addInfo'/>
            </button>

            <div className='playlistActionsDropdown'>
                <AnimatePresence>
                  {showPlaylistActions && (
                    <motion.div className='PLactionDropwdownWrapper' key="modal" initial={{ height: 0, opacity: 0 }} animate={{ 
                      height: (width <= 768) ? "auto" : 150, opacity: 1, 
                      transition:{type: "spring", bounce: .3, opacity: {delay: 0.025}, duration: .5}
                    }} exit={{ height: 0, opacity: 0 }} transition={{duration: .5, type: "spring", bounce: .3}}>
                      <div className='PLactionDropdown'>
                        <span className='PLtypeInfo'>SONG</span>
                        <span className='PLsongInfo'>{songName}</span>
                        <img className='PLdivider2' src='./divider2.svg' width={200} alt='divider' ></img>
                        <span className='PPLactionItemWrapper'><a className='PLactionBtnItem' href={'https://open.spotify.com/track/' + uid} target='_blank' rel="noreferrer"> 
                        <img className='PLactionBtnIcon' src='./copyLink.svg' alt='copyLink'/><span className='PLactionBtnItemTxT'>Open Song</span></a></span>
                        {userID === addedBy? (
                          <span className='PPLactionItemWrapper'><span className='PLactionBtnItem' onClick={() => likes < 2 ? removeItem(uid, userID, token) : showDeleteAlert(true)}> <img className='PLactionBtnIcon' src='./delete.svg' alt='delete'/><span className='PLactionBtnItemTxT'>Delete</span></span></span>
                        ) : (<span className='PPLactionItemWrapper'><a href={`mailto:info@nicodenetworks.com?subject=[Schulball24] Item Report (${songName}, ${uid} / ${userID})`} target='_blank' rel="noreferrer" className='PLactionBtnItem'> <img className='PLactionBtnIcon' src='./report.svg' alt='report'/><span className='PLactionBtnItemTxT'>Report Vote</span></a></span>)}
                      </div>
                      </motion.div>
                  )}
                </AnimatePresence>
            </div>
            </div>
        </div>
    )
}

export default PlaylistItem;