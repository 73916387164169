import "./NotFound.css";

function NotFound(){
    return (
        <div className="NotFound">
            <div className="NotFoundContent">
                <div className="NotFoundBG"><img src="./404-bg.png" alt="404 - not found"></img></div>
                <div className="NotFoundTitle">looks like this page might have been removed...</div>
                <div className="NotFoundSubtitle">report problems: <span style={{color: '#e5a800'}}>info@nicodenetworks.com</span></div>
            </div>
        </div>
    )
}

export default NotFound;