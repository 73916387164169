import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import {useRef } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import {motion, AnimatePresence } from 'framer-motion';


const PlaylistItem = ({uid, songName, coverImage, songArtists, likes}) => {

  const [like] = useState(likes);
  const [showPlaylistActions, setShowPlaylistActions] = useState(false);
  //const {setSong, pauseSong} = useAudio();

  let menuRef = useRef();

  useEffect(() => {
    const closeDropdown = e => {
      if(!menuRef.current?.contains(e.target)){
        setShowPlaylistActions(false);
      }
      }
    document.body.addEventListener('click', closeDropdown);
    return () => document.body.removeEventListener('click', closeDropdown);
    
  }, []);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

    return(
     
        <div className='PlaylistItem' style={{zIndex: showPlaylistActions ? (width >= 768) ? "10" : "99" : "1" }}>
          <div className='PlaylistItemInfo'>
            <div className='PLImageHelper'>
              <div className='PLPreviewPlayer'>
              </div>
              
              <img className='PLImage' src={coverImage} width='90px' alt='songImg'></img>
            </div>
            <div className='PLSongInfo'>
              <div className='PLSTitle'>{songName}</div>
              <div className='PLArtist'>{songArtists}</div>
            </div>
          </div>
          <div className='PLSongLikeField'>
            <motion.div >
              <motion.img className='PLSongLikeImg' src='like.svg' alt='likeIcon' width='25px' initial={{ opacity: 0, scale: 0.5 }} 
              animate={{ opacity: 1, scale: 1 }}></motion.img>
            </motion.div>
            {/*}
            <motion.svg width="33" height="29" viewBox="0 0 33 29" fill="none" xmlns="http://www.w3.org/2000/svg" initial={{scale: .1}} animate={{scale: 1}} onClick={() => btnAnim.start({scale: [.3, 1]})}>
              <path id="Intersect" d="M16.5 26.8604L3.55715 13.6954C0.814285 10.9055 0.814285 6.37172 3.55715 3.58179C6.28603 0.806071 10.6997 0.806071 13.4286 3.58179L15.4304 5.61793L16.5 6.70593L17.5696 5.61793L19.5714 3.58179C22.3003 0.806071 26.714 0.806071 29.4429 3.58179C32.1857 6.37172 32.1857 10.9055 29.4429 13.6954L16.5 26.8604Z" stroke="#7F7F7F" stroke-width="3"/>
              </motion.svg>*/}
                <motion.div className='PLSongLikeCount' initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }}
              transition={{  type: 'spring', duration: 0.8, bounce: .4 }}>{like}</motion.div>
          </div>
          <div ref={menuRef} className='PLSongAddInfo'>
            <button onClick={() => setShowPlaylistActions(!showPlaylistActions)}>
            <img className={'PLSongAddInfoIcon' + uid} src='verticalDropdown.svg' height={100} alt='addInfo'/>
            </button>

            <div className='playlistActionsDropdown'>
                <AnimatePresence>
                  {showPlaylistActions && (
                    <motion.div className='PLactionDropwdownWrapper'key="modal" initial={{ height: 0, opacity: 0 }} animate={{ 
                      height: (width <= 768) ? "auto" : 150, opacity: 1, 
                      transition:{type: "spring", bounce: .3, opacity: {delay: 0.025}, duration: .5}
                    }} exit={{ height: 0, opacity: 0 }} transition={{duration: .5, type: "spring", bounce: .3}}>
                      <div className='PLactionDropdown'>
                        <span className='PLtypeInfo'>SONG</span>
                        <span className='PLsongInfo'>{songName}</span>
                        <img className='PLdivider2' src='./divider2.svg' width={200} alt='divider'></img>
                        <span className='PPLactionItemWrapper'><a className='PLactionBtnItem' href={'https://open.spotify.com/track/' + uid} target='_blank' rel="noreferrer"> <img className='PLactionBtnIcon' src='./copyLink.svg' alt='copyLink'/><span className='PLactionBtnItemTxT'>Open Song</span></a></span>
                        <span className='PPLactionItemWrapper'><a href={`mailto:info@nicodenetworks.com?subject=[Schulball24] Item Report (${songName}, ${uid})`} target='_blank' rel="noreferrer" className='PLactionBtnItem'> 
                        <img className='PLactionBtnIcon' src='./report.svg' alt='report'/><span className='PLactionBtnItemTxT'>Report Vote</span></a></span>
                      </div>
                      </motion.div>
                  )}
                </AnimatePresence>
            </div>
            </div>
        </div>
    )
}

export default PlaylistItem;